import type { ComponentPropsWithoutRef } from 'react';

import { TextArea } from '../Inputs';

import { useUsefulField } from './useUsefulField';

type FormTextAreaProps = Omit<
  ComponentPropsWithoutRef<typeof TextArea>,
  'defaultValue' | 'error' | 'onChange' | 'value'
> & { name: string };

export const FormTextArea = ({ name, ...restProps }: FormTextAreaProps) => {
  const { value, onBlur, touched, error, setValue } = useUsefulField<string>(name);

  return (
    <TextArea
      name={name}
      value={value ?? ''}
      onChange={e => setValue(e.currentTarget.value, true)}
      onBlur={onBlur}
      error={touched && error ? error : undefined}
      {...restProps}
    />
  );
};
