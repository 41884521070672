import { type ComponentPropsWithoutRef } from 'react';

import { IntegerInput } from '../Inputs';

import { useUsefulField } from './useUsefulField';

type FormIntegerProps = Omit<
  ComponentPropsWithoutRef<typeof IntegerInput>,
  'defaultValue' | 'error' | 'onChange' | 'value'
> & {
  name: string;
};

export const FormIntegerInput = ({ name, ...restProps }: FormIntegerProps) => {
  const { value, setValue, setTouched, touched, error, onBlur } =
    useUsefulField<ComponentPropsWithoutRef<typeof IntegerInput>['value']>(name);

  return (
    <IntegerInput
      name={name}
      value={value}
      onChange={v => {
        void setTouched(true);
        void setValue(v, true);
      }}
      onBlur={onBlur}
      error={touched && error ? error : undefined}
      {...restProps}
    />
  );
};
