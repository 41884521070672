import type { ComponentPropsWithoutRef } from 'react';

import { Checkbox } from '../Inputs';

import { useUsefulField } from './useUsefulField';

type FormCheckboxProps = Omit<
  ComponentPropsWithoutRef<typeof Checkbox>,
  'checked' | 'defaultValue' | 'error' | 'onChange' | 'value'
> & { name: string };

export const FormCheckbox = ({ name, ...restProps }: FormCheckboxProps) => {
  const { value, onBlur, touched, error, setValue } = useUsefulField<boolean>(name);

  return (
    <Checkbox
      name={name}
      checked={value ?? false}
      onChange={e => setValue(e, true)}
      onBlur={onBlur}
      error={touched && error ? error : undefined}
      {...restProps}
    />
  );
};
