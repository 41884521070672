import clsx from 'clsx';
import type { ComponentPropsWithoutRef } from 'react';

import { ArrowIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';

import styles from './ProceedButton.module.scss';

type ProceedButtonProps = ComponentPropsWithoutRef<typeof Button> & { text?: string; className?: string };

export const ProceedButton = ({ text = 'Proceed', className, ...restProps }: ProceedButtonProps) => (
  <Button
    variation='faded'
    title='Next Step'
    className={clsx(styles['next-button'], className)}
    tabIndex={0}
    {...restProps}>
    {text}
    <ArrowIcon width={32} color='#627EEA' />
  </Button>
);
