import { useField } from 'formik';
import type { ComponentProps } from 'react';

import { US_STATES } from '@endaoment-frontend/constants';
import type { CountryCode } from '@endaoment-frontend/types';

import { Select } from '../Inputs';

import { FormInput } from './FormInput';

type StateFormInputProps = Omit<
  ComponentProps<typeof Select>,
  'error' | 'label' | 'name' | 'onChange' | 'options' | 'placeholder' | 'value'
> & {
  name: string;
  countryFieldName: string;
};

const DEFAULT_STATE = '';

export const StateFormInput = ({ name, countryFieldName, ...props }: StateFormInputProps) => {
  const [{ value: countryValue }] = useField<CountryCode>(countryFieldName);
  const [{ value }, { error }, { setValue }] = useField(name);

  if (countryValue === 'USA') {
    const stateOptions = US_STATES.options.map(state => ({ display: state, value: state }));
    return (
      <Select
        name={name}
        label='State'
        options={stateOptions}
        placeholder='Select a State'
        value={value ?? DEFAULT_STATE}
        onChange={e => setValue(e.currentTarget.value, true)}
        error={error}
        {...props}
      />
    );
  }
  return <FormInput name={name} label='Region' placeholder='Enter a Region' />;
};
