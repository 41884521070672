import type { ComponentPropsWithoutRef } from 'react';

import { Input } from '../Inputs';

import { useUsefulField } from './useUsefulField';

type FormInputProps = Omit<ComponentPropsWithoutRef<typeof Input>, 'defaultValue' | 'error' | 'onChange' | 'value'> & {
  name: string;
};

export const FormInput = ({ name, ...restProps }: FormInputProps) => {
  const { value, onChange, onBlur, touched, error } = useUsefulField<string>(name);

  return (
    <Input
      name={name}
      value={value ?? ''}
      onChange={onChange}
      onBlur={onBlur}
      error={touched && error ? error : undefined}
      {...restProps}
    />
  );
};
