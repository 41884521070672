import type { InputWrapperProps } from './InputWrapper';
import { InputWrapper } from './InputWrapper';

type TextAreaProps = JSX.IntrinsicElements['textarea'] &
  Omit<InputWrapperProps, 'children'> & { inputClassName?: string };

export const TextArea = ({
  name,
  label,
  leftElements,
  rightElements,
  error,
  className,
  inputClassName,
  ...restProps
}: TextAreaProps) => {
  return (
    <InputWrapper
      className={className}
      name={name}
      label={label}
      error={error}
      leftElements={leftElements}
      rightElements={rightElements}>
      <textarea name={name} id={name} className={inputClassName} {...restProps} />
    </InputWrapper>
  );
};
