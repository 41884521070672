import clsx from 'clsx';
import type { ReactNode } from 'react';

import { ErrorDisplay } from './ErrorDisplay';
import styles from './Input.module.scss';

export type InputWrapperProps = {
  children: ReactNode;
  name?: string;
  label?: ReactNode;
  leftElements?: ReactNode;
  rightElements?: ReactNode;
  error?: ReactNode;
  className?: string;
  errorClassName?: string;
  plain?: boolean;

  // `rightAligned` is passed to the `ErrorMessage` component, it's used to align the error message to the right of the input.
  // TODO: Apply this new prop to all relevant right aligned `Input`s
  rightAligned?: boolean;
};

export const InputWrapper = ({
  children,
  name,
  label,
  leftElements,
  rightElements,
  error,
  className,
  errorClassName,
  rightAligned,
  plain,
}: InputWrapperProps) => {
  return (
    <div className={clsx(styles.input, className, plain && styles['input--plain'])}>
      {!!label && (
        <div className={styles['label-container']} data-component='label-container'>
          <label htmlFor={name}>{label}</label>
        </div>
      )}
      <div className={styles['input-container']} data-component='input-container'>
        {!!leftElements && (
          <span className={clsx(styles.elements, styles['elements--left'])} data-component='left-elements'>
            {leftElements}
          </span>
        )}
        {children}
        {!!rightElements && (
          <span className={clsx(styles.elements, styles['elements--right'])} data-component='right-elements'>
            {rightElements}
          </span>
        )}
      </div>
      <ErrorDisplay error={error} className={clsx(styles.elements, errorClassName)} rightAligned={rightAligned} />
    </div>
  );
};
