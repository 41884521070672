import { useField } from 'formik';
import type { ComponentProps } from 'react';

import type { CountryCode } from '@endaoment-frontend/types';

import { FormInput } from './FormInput';

type ZipFormInputProps = Omit<ComponentProps<typeof FormInput>, 'label' | 'placeholder'> & {
  countryFieldName: string;
};

export const ZipFormInput = ({ name, countryFieldName, ...props }: ZipFormInputProps) => {
  const [{ value: countryValue }] = useField<CountryCode>(countryFieldName);
  return (
    <FormInput
      name={name}
      label={countryValue === 'USA' ? 'Zip Code' : 'Postal Code'}
      placeholder={countryValue === 'USA' ? 'Enter a Zip Code' : 'Enter a Postal Code'}
      {...props}
    />
  );
};
