import type { ComponentPropsWithoutRef } from 'react';

import { Select } from '../Inputs';

import { useUsefulField } from './useUsefulField';

type FormSelectProps = Omit<
  ComponentPropsWithoutRef<typeof Select>,
  'defaultValue' | 'error' | 'onChange' | 'value'
> & {
  name: string;
};

export const FormSelect = ({ name, ...restProps }: FormSelectProps) => {
  const { value, onChange, onBlur, touched, error } = useUsefulField<string>(name);

  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={touched && error ? error : undefined}
      {...restProps}
    />
  );
};
