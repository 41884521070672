import clsx from 'clsx';

import styles from './Checkbox.module.scss';
import type { InputWrapperProps } from './InputWrapper';
import { InputWrapper } from './InputWrapper';

type CheckboxProps = Omit<InputWrapperProps, 'children'> &
  Omit<JSX.IntrinsicElements['div'], 'onChange' | 'onClick'> & {
    wrapperClassName?: string;
    inputClassName?: string;
    onChange: (e: boolean) => void;
    checked: boolean;
    disabled?: boolean;
    isMatchingBackground?: boolean;
  };

export const Checkbox = ({
  checked,
  onChange,
  label,
  disabled,
  name,
  leftElements,
  rightElements,
  error,
  className,
  wrapperClassName,
  isMatchingBackground,
  ...restProps
}: CheckboxProps) => {
  return (
    <InputWrapper
      name={name}
      leftElements={leftElements}
      rightElements={rightElements}
      error={error}
      className={clsx(styles['checkbox-wrapper'], wrapperClassName)}>
      <div
        className={clsx(styles['checkbox'], disabled && styles['checkbox--disabled'], className)}
        onClick={() => !disabled && onChange(!checked)}
        {...restProps}>
        <div className={styles['checkbox-box']} role='checkbox' aria-checked={checked}>
          <div
            className={clsx(
              styles['checkbox-box-checkmark'],
              isMatchingBackground && styles['checkbox-box-checkmark--current-color'],
              checked && styles['checkbox-box-checkmark--checked'],
            )}
          />
        </div>
        <label>{label}</label>
      </div>
    </InputWrapper>
  );
};
